import axios from "axios"
import { useState } from "react"
import { URLBACKEND } from "../api/serverapi"


export function Add(){
    const[ticker,setTicker]=useState('')
    const[message,setMessage]=useState('')
    const[loading,setLoading]=useState('Submit')
    const user_id=sessionStorage.getItem('user_id')

    const addCompany=async()=>{
        try{
            setLoading('Loading')
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`
                
            };
            const cuerpo={
                'user_id':user_id,
                'company':ticker
            }
            const response = await axios.post(`${URLBACKEND}/companies/add_company`, cuerpo,{params:{user_id:user_id,company:ticker}, headers, withCredentials: true });

            let data=response.data
            console.log(data)
            if (data.message=='Post successfuly created'){
                setMessage('')
                setLoading('Submit')
                
            }else{setMessage('Error')}

        }catch(err){
            setMessage('Error')
            setLoading('Submit')
            console.log(err)}
    }

    return(
        <div id="search">
            <form onSubmit={(e)=>{
                if(ticker==''){
                    e.preventDefault()
                    setMessage('Field required')
                }else{e.preventDefault()
                    addCompany() }
            }}>
                <input onChange={(e)=>setTicker(e.target.value)} placeholder="Ticker"/>
                <button >{loading}</button>
                <p>{message}</p>
                
            </form>
        </div>
    )
}