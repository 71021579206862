import axios from "axios"
import { Link } from "react-router-dom"
import { useState,useRef, useEffect } from "react"
import { URLBASE,URLBACKEND } from "../api/serverapi"
import { FaBars } from "react-icons/fa";


export function Nav(){

    const user_id=sessionStorage.getItem('user_id')

    const logOut=async()=>{
        try{
            let response=await axios.get(`${URLBACKEND}/logout`)
            let data=response.data
            console.log(data)
            if(data.message=='Logout successful'){
                window.location.href=`${URLBASE}`
            }
        }catch(err){console.log(err)}
    }

    const deleteUser=async()=>{
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`
        };
       try{
        let response=await axios.delete(`${URLBACKEND}/users/delete_user/${user_id}`,{headers,withCredentials:true})
        let data=response.data
        console.log(data)
       }catch(err){console.log(err)}

        
    }
    
    return(
        <div  id="nav">
        <ul style={{margin:0,padding:0}}>
            <li><Link to={`${URLBASE}`}>Home</Link></li>

            

            <li><Link  to={`${URLBASE}/addcompany`} >Add company</Link></li>


            <li><Link to={`${URLBASE}/signup`}>Signup</Link></li> 

            <li><Link  to={`${URLBASE}/login`}>Login</Link></li> 
            
            <li><Link  onClick={()=>{
                logOut()
                sessionStorage.setItem('username','')
                sessionStorage.setItem('user_id','')
                window.location.reload()
            }}>Logout</Link></li>  
            <li><Link  onClick={()=>{deleteUser()
                sessionStorage.setItem('username','')
                sessionStorage.setItem('user_id','')
                window.location.reload()
            }}>Delete User</Link></li>
        </ul>
        
        </div>
    )
}
