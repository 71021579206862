import {Route, Routes } from 'react-router-dom';
import { Signup } from './signup';
import { Login } from './login';
import { URLBASE } from '../api/serverapi';
import { useRef } from 'react';

import { useEffect } from 'react';
import { Get } from './get';
import { Add } from './add';
import { Home } from './home';
import { Nav } from './nav';

export function App(){

    useEffect(()=>{
        
    let username=sessionStorage.getItem('username')
    if(username){
        
    }else{window.location.href=`${URLBASE}/login`}
    },[])

    return(
        <Routes>
          <Route path='/' element={<><Nav/><Home/></>}/>
          <Route path='/addcompany' element={<><Nav/><Add/></>}/>
          <Route path='/getcompany/:company_id' element={<><Nav/><Get/></>}/>
          <Route path={`${URLBASE}/login`} element={<><Nav/><Login/></>}/>
          <Route path={`${URLBASE}/signup`} element={<><Nav/><Signup/></>}/>
          
        </Routes>
    )
}
