import { useEffect, useState } from "react"
import { URLBACKEND, URLBASE } from "../api/serverapi"
import axios from "axios"
import { Link } from "react-router-dom"

export function Home(){

const[datacompanies,setDatacompanies]=useState([])
const[loadingdelete,setLoadingdelete]=useState(['delete'])
const[loading,setLoading]=useState('Refresh')

const deleteCompany=async(id)=>{
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`
        };
        try{
            let response=await axios.delete(`${URLBACKEND}/companies/delete_company`,{params:{company_id:id},headers,withCredentials:true})
            let data=response.data
            console.log(data)
            window.location.reload()
        }catch(err){console.log(err)}
}

const updateCompany=async(id)=>{
    try{
        const user_id=sessionStorage.getItem('user_id')
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`
        };
        let response=await axios.put(`${URLBACKEND}/companies/update_company`,{},{params:{user_id:user_id,company_id:id},headers,withCredentials:true})
        let data=response.data
        console.log(data)
        

    }catch(err){console.log(err)
    }
        
}

const getCompanies=async()=>{
    try{
        const user_id=sessionStorage.getItem('user_id')
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`
        };
        let response=await axios.get(`${URLBACKEND}/companies/`,{params:{user_id:user_id},headers,withCredentials:true})
        let data=response.data
        console.log(data)
        setDatacompanies(data.data)
    }catch(err){console.log(err)}
}    

useEffect(()=>{
    getCompanies()
},[])

return(
    <div>
    <form onSubmit={(e)=>{
        e.preventDefault()
        for(let i of datacompanies){
            updateCompany(i.id)
        }
    }}>
    <button id="refresh">{loading}</button>
    </form>
    <div id="box">
    <table className="styled-table">
    <thead >
        <tr >
        <th>name</th>
                    <th>qtotalrevenue</th>
                    <th>qtotalrevenue1</th>
                    <th>qtotalrevenue2</th>
                    <th>qtotalrevenue3</th>
                    <th>qgrossprofit</th>
                    <th>qgrossprofit1</th>
                    <th>qgrossprofit2</th>
                    <th>qgrossprofit3</th>
                    <th>totalrevenue</th>
                    <th>totalrevenue1</th>
                    <th>totalrevenue2</th>
                    <th>totalrevenue3</th>
                    <th>grossprofit</th>
                    <th>grossprofit1</th>
                    <th>grossprofit2</th>
                    <th>grossprofit3</th>
                    <th>qtotalassets</th>
                    <th>qtotalassets1</th>
                    <th>qtotalassets2</th>
                    <th>qtotalassets3</th>
                    <th>qtotalliabilities</th>
                    <th>qtotalliabilities1</th>
                    <th>qtotalliabilities2</th>
                    <th>qtotalliabilities3</th>
                    <th>totalassets</th>
                    <th>totalassets1</th>
                    <th>totalassets2</th>
                    <th>totalassets3</th>
                    <th>totalliabilities</th>
                    <th>totalliabilities1</th>
                    <th>totalliabilities2</th>
                    <th>totalliabilities3</th>
                    <th>dividendyield</th>
                    <th>qearningestimate</th>
                    <th>qearningestimate1</th>
                    <th>qearningestimate2</th>
                    <th>qearningestimate3</th>
                    <th>stock</th>
                    <th>stock1</th>
                    <th>stock2</th>
                    <th>stock3</th>
                    <th>eps</th>
                    <th>ps</th>
                    <th>ordinary_shares</th>
                    <th>delete</th>
        </tr>
    </thead>
    <tbody  id="table_box">
    {(datacompanies.map((company,k)=>{
            return(<tr  key={k}>
                    <td><Link to={`${URLBASE}/getcompany/${company.id}`}>{company.name}</Link></td>
                    <td>{company.qtotalrevenue}</td>
                    <td>{company.qtotalrevenue1}</td>
                    <td>{company.qtotalrevenue2}</td>
                    <td>{company.qtotalrevenue3}</td>
                    <td>{company.qgrossprofit}</td>
                    <td>{company.qgrossprofit1}</td>
                    <td>{company.qgrossprofit2}</td>
                    <td>{company.qgrossprofit3}</td>
                    <td>{company.totalrevenue}</td>
                    <td>{company.totalrevenue1}</td>
                    <td>{company.totalrevenue2}</td>
                    <td>{company.totalrevenue3}</td>
                    <td>{company.grossprofit}</td>
                    <td>{company.grossprofit1}</td>
                    <td>{company.grossprofit2}</td>
                    <td>{company.grossprofit3}</td>
                    <td>{company.qtotalassets}</td>
                    <td>{company.qtotalassets1}</td>
                    <td>{company.qtotalassets2}</td>
                    <td>{company.qtotalassets3}</td>
                    <td>{company.qtotalliabilities}</td>
                    <td>{company.qtotalliabilities1}</td>
                    <td>{company.qtotalliabilities2}</td>
                    <td>{company.qtotalliabilities3}</td>
                    <td>{company.totalassets}</td>
                    <td>{company.totalassets1}</td>
                    <td>{company.totalassets2}</td>
                    <td>{company.totalassets3}</td>
                    <td>{company.totalliabilities}</td>
                    <td>{company.totalliabilities1}</td>
                    <td>{company.totalliabilities2}</td>
                    <td>{company.totalliabilities3}</td>
                    <td>{company.dividendyield}</td>
                    <td>{company.qearningestimate}</td>
                    <td>{company.qearningestimate1}</td>
                    <td>{company.qearningestimate2}</td>
                    <td>{company.qearningestimate3}</td>
                    <td>{company.stock}</td>
                    <td>{company.stock1}</td>
                    <td>{company.stock2}</td>
                    <td>{company.stock3}</td>
                    <td>{company.eps}</td>
                    <td>{company.ps}</td>
                    <td>{company.ordinary_shares}</td>
                <td>    
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    deleteCompany(company.id)
                }}>
                <button>{loadingdelete}</button>
                </form>
                </td>
            </tr>)
        }))}
    </tbody>
    </table>
    </div>
    </div>
)
}

