import axios from "axios"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { URLBACKEND } from "../api/serverapi"


export function Get(){

    const {company_id}=useParams()

    const getCompany=async()=>{
        try{
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`
                
            }
            let response=await axios.get(`${URLBACKEND}/companies/get_company/${company_id}`,{headers,withCredentials:true})
            let data=response.data
            console.log(data)


        }catch(err){console.log(err)}
    }

    useEffect(()=>{
        getCompany()
    },[])

    return(
        <h1>Ga</h1>
    )
}