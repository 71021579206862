import { useState,useRef } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { URLBASE,URLBACKEND } from "../api/serverapi"

export function Signup(){

    const[username,setUsername]=useState('')
    const[password,setPassword]=useState('')
    const[email,setEmail]=useState('')
    const[message,setMessage]=useState('')
    const[loading,setLoading]=useState('Send')

    const login=async()=>{
        try{
            const formData = new URLSearchParams()
            formData.append('username', username)
            formData.append('password', password)

            const headers={
                'Content-Type':'application/x-www-form-urlencoded',
            'Authorization': ''
            }
            let response=await axios.post(`${URLBACKEND}/users/login`,formData,{params:{username:username,password:password},headers,withCredentials:true})

            let data=response.data
            if(data.message=='Loged'){
                sessionStorage.setItem('username',data.user.username)
                sessionStorage.setItem('user_id',data.user.id)
                sessionStorage.setItem('access_token',data.access_token)
                window.location.href=`${URLBASE}`
            }else{
                setMessage(data.message)
                setLoading('Send')
            }

        }catch(err){console.log(err)}
    }

    const createUser=async()=>{
        try{
            let body={
                'username':username,
                'password':password,
                'email':email,
            }

            let response=await axios.post(`${URLBACKEND}/users/create_user`,body)
            let data=response.data
            console.log(response)
            if(data.message='User successfuly created'){
                login()
            }else{}

        }catch(err){console.log(err)
            setMessage('Error has occurred')
            setLoading('Send')
        }
            
    }

    return(
        <form className="signup_form" onSubmit={(e)=>{
            e.preventDefault()
            if(username==''||password==''||email==''){
                setMessage('All fields required')
                setLoading('Send')
            }else{createUser()}
           
        }}>
            <h1>Sign up</h1>
            <input onChange={(e)=>setUsername(e.target.value)} placeholder="Username"/>

            <input onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="Password"/>

            <input onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Email"/>

            <h2 id="message">{message}</h2>

            <button onClick={()=>setLoading('Loading')}>{loading}</button>
            <h4><Link to={`${URLBASE}/login`}>Login</Link></h4>
        </form>
    )
}