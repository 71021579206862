import axios from "axios"
import { useState,useRef } from "react"
import { Link } from "react-router-dom"
import { URLBACKEND,URLBASE } from "../api/serverapi"

export function Login(){

    const[username,setUsername]=useState('')
    const[password,setPassword]=useState('')
    const[message,setMessage]=useState('')
    const[loading,setLoading]=useState('Send')

    const login=async()=>{
        try{
            const formData = new URLSearchParams()
            formData.append('username', username)
                formData.append('password', password)

            const headers={
                'Content-Type':'application/x-www-form-urlencoded',
            'Authorization': ''
            }
            let response=await axios.post(`${URLBACKEND}/users/login`,formData,{params:{username:username,password:password},headers,withCredentials:true})

            let data=response.data
            if(data.message=='Loged'){
                sessionStorage.setItem('username',data.user.username)
                sessionStorage.setItem('user_id',data.user.id)
                sessionStorage.setItem('access_token',data.access_token)
                window.location.href=`${URLBASE}`
            }else{
                
                
            }

        }catch(err){
            setMessage('Error')
            setLoading('Send')
            console.log(err)}
    }

    return(
        <form className="login_form" onSubmit={(e)=>{
            e.preventDefault()
            if(username==''||password==''){
                setMessage('All fields required')
                setLoading('Send')
            }else{login()}
            
        }}>
            <h1>Login</h1>
            <input onChange={(e)=>setUsername(e.target.value)} placeholder="Username"/>

            <input onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="Password"/>

            <h2 id="message">{message}</h2>
            
            <button onClick={()=>
            setLoading('Loading')}>{loading}</button>
            <h4><Link to={`${URLBASE}/signup`}>Create account</Link></h4>
        </form>
    )
}